import React from 'react';

import './view.scss';

import { handleTokenExpiration } from 'utilities/tokenExpiration/TokenExpiration';

import paypo from 'assets/images/logo_PayPo_checkout.svg';

const PaymentGatewayHeader = ({ showExpiration, fee }) => {
    const paymentProvider = sessionStorage.getItem('provider');
    const transactionDescription = sessionStorage.getItem('description');
    const shopName = sessionStorage.getItem('shopName');

    const defaultPaymentProviderInfo = {
        headerTitle: 'Płatność:',
        headerShopName: transactionDescription,
    };

    const paymentTypes = {
        'p24-payment': defaultPaymentProviderInfo,
        'payu-payment': defaultPaymentProviderInfo,
    };

    const paymentInfo = paymentTypes[paymentProvider] || {
        headerTitle: 'Sprzedawca:',
        headerShopName: shopName,
    };

    const { headerTitle, headerShopName } = paymentInfo;

    const handleAmountToDisplay = () => {
        const amount = parseInt(sessionStorage.getItem('amount'), 10);
        const displayAmount = (amount / 100).toFixed(2).replace('.', ',');

        return displayAmount;
    };

    return (
        <header className="header">
            <div className="header__logos">
                <img
                    className="header__paypo"
                    src={paypo}
                    alt="paypo"
                />

                {showExpiration && handleTokenExpiration()}
            </div>

            <div className="header__details">
                <hr className="header__line" />

                <div className="header__info">
                    <div className="header__shop-name">
                        <span className="header__title">{headerTitle}</span>

                        <span className="header__hightlight">
                            {headerShopName}
                        </span>

                        <div className="header__amount">
                            <span className="header__amountTitle">
                                Do zapłaty:
                            </span>

                            <span className="header__hightlight">
                                {handleAmountToDisplay()} zł
                            </span>

                            {!!fee && (
                                <p>
                                    (Do kwoty przelewu doliczana jest opłata{' '}
                                    {fee} zł na rzecz operatora płatności za
                                    obsługę e-płatności)
                                </p>
                            )}
                        </div>
                    </div>

                    <div className="header__container" />
                </div>
            </div>
        </header>
    );
};

export default PaymentGatewayHeader;
