import React, { useEffect, useState } from 'react';
import isWebview from 'is-ua-webview';

import './view.scss';

import RedirectAfterTwentySeconds from 'utilities/redirectAfterTwentySecs/RedirectAfterTwentySecs';

import PaymentGatewayFooter from 'components/footer/PaymentGatewayFooter';
import PaymentStatusDetails from 'components/paymentStatusDetails/PaymentStatusDetails';
import CheckoutHeader from 'components/checkoutHeader/CheckoutHeader';

import plane from 'assets/images/plane.svg';

const Checkout = ({ paymentData, redirect }) => {
    const [buttonText, setButtonText] = useState('');
    const shopName = sessionStorage.getItem('shopName');

    useEffect(() => {
        const webview = isWebview(window.navigator.userAgent);

        if (webview) {
            setButtonText('Wróć do aplikacji');
        } else {
            setButtonText(`Wróć do ${shopName}`);
        }
    }, []);

    return (
        <div className="checkout">
            <main className="complete">
                <div className="main__container">
                    <img
                        className="main__icon"
                        src={plane}
                        alt="plane"
                    />
                    <p className="main__subheadline">Udało się!</p>
                    <h2 className="headline">Zamówienie opłacone!</h2>
                    <p className="main__description">
                        Szczegóły transakcji znajdziesz w e-mailu,
                        <br />
                        który wysłaliśmy na podany przez Ciebie adres.
                    </p>
                    <PaymentStatusDetails paymentData={paymentData} />
                    <a
                        className="main__back"
                        href={sessionStorage.getItem('returnUrl')}
                    >
                        {buttonText}
                    </a>
                    {redirect && <RedirectAfterTwentySeconds />}
                    <PaymentGatewayFooter />
                </div>
            </main>
            <CheckoutHeader />
        </div>
    );
};

export default Checkout;
