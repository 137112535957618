import React from 'react';

import './view.scss';

const PaymentGatewayFooter = ({ showLinks = true }) => (
    <footer className="footer-status">
        <p>
            Masz pytania?
            <br />
            Napisz{' '}
            <a
                className="footer-status__contact"
                href="mailto:bok@paypo.pl"
            >
                bok@paypo.pl
            </a>{' '}
            lub zadzwoń{' '}
            <a
                className="footer-status__contact"
                href="tel:+48223337460"
            >
                +48 22 333 74 60
            </a>
        </p>
        {showLinks && (
            <ul className="footer-status__links">
                <li className="footer-status__link-container">
                    <a
                        href={`${process.env.APP_URL}/regulamin-bramki-platniczej`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer-status__link"
                    >
                        Regulamin
                    </a>
                </li>
                <li className="footer-status__link-container">
                    <a
                        href={`${process.env.APP_URL}/informacja-o-przetwarzaniu-danych-osobowych/#bramka-platnicza-paypo`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer-status__link"
                    >
                        Obowiązek informacyjny
                    </a>
                </li>
            </ul>
        )}
    </footer>
);

export default PaymentGatewayFooter;
