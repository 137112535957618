import React from 'react';

import './view.scss';

import spinner from 'assets/images/spinner--keppel.svg';

const LoaderView = () => (
    <main className="loader">
        <img
            className="loader__spinner"
            src={spinner}
            alt="spinner"
        />
    </main>
);

export default LoaderView;
