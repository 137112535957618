import React from 'react';

import Checkout from 'components/checkout/Checkout';
import StatusPayment from 'components/statusPayment/StatusPayment';

const PaymentView = ({ redirect, paymentData, paymentStatus }) => {
    const isSseResponse =
        sessionStorage.getItem('sseResponse') === 'true' &&
        sessionStorage.getItem('emailSource') === 'false';

    return isSseResponse ? (
        <Checkout
            redirect={redirect}
            paymentData={paymentData}
        />
    ) : (
        <StatusPayment
            paymentStatus={paymentStatus}
            paymentData={paymentData}
        />
    );
};

export default PaymentView;
