export const compareSignaturePayment = (payment, transaction) => {
    const signatureCheck = window.location.search.split('s=').pop();

    if (!signatureCheck) return null;

    if (signatureCheck !== sessionStorage.getItem('signature')) {
        sessionStorage.clear();
        sessionStorage.setItem('signature', signatureCheck);
        sessionStorage.setItem('transaction', transaction);
        sessionStorage.setItem('payment', payment);
    }
};
