import React from 'react';

import './view.scss';

import PaymentGatewayFooter from 'components/footer/PaymentGatewayFooter';
import CheckoutHeader from 'components/checkoutHeader/CheckoutHeader';

import sign from 'assets/images/sign.svg';

const AccessDenied = () => (
    <div className="access-denied">
        <div className="content">
            <main className="error">
                <div className="main__container">
                    <img
                        className="main__icon"
                        src={sign}
                        alt="sign"
                    />
                    <h2 className="headline">Brak dostępu</h2>
                    <p className="main__description">
                        Nie masz uprawnień do tej strony.
                    </p>
                    <span className="error--margin" />
                    <PaymentGatewayFooter />
                </div>
            </main>
        </div>
        <CheckoutHeader />
    </div>
);

export default AccessDenied;
