import React, { useEffect } from 'react';

import './view.scss';

import PaymentGatewayFooter from 'components/footer/PaymentGatewayFooter';
import CheckoutHeader from 'components/checkoutHeader/CheckoutHeader';

import sign from 'assets/images/sign.svg';

const NotFound = () => {
    useEffect(() => {
        sessionStorage.clear();
    }, []);

    return (
        <div className="not-found">
            <div className="content">
                <main className="error">
                    <div className="main__container">
                        <img
                            className="main__icon"
                            src={sign}
                            alt="sign"
                        />
                        <h2 className="headline">404</h2>
                        <p className="main__description">
                            Strona, której szukasz nie istnieje.
                        </p>
                        <span className="error--margin" />
                        <PaymentGatewayFooter />
                    </div>
                </main>
            </div>
            <CheckoutHeader />
        </div>
    );
};

export default NotFound;
