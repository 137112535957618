export const compareSignature = transaction => {
    const signatureCheck = window.location.search.split('=').pop();

    if (
        signatureCheck !== sessionStorage.getItem('signature') &&
        window.location.search
    ) {
        sessionStorage.clear();
        sessionStorage.setItem('signature', signatureCheck);
        sessionStorage.setItem(
            'transaction',
            window.location.pathname
                .split('/transactions/')
                .pop()
                .split('/status')
                .shift(),
        );
        sessionStorage.setItem('transaction', transaction);
    }
};
