import React from 'react';

import './view.scss';

import paypo from 'assets/images/logo_PayPo_checkout.svg';

const CheckoutHeader = () => (
    <header className="checkout-header">
        <div className="checkout-header__logos">
            <img
                className="checkout-header__paypo"
                src={paypo}
                alt="paypo"
            />
        </div>
    </header>
);

export default CheckoutHeader;
