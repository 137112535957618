import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import PaymentGatewayMethods from 'components/paymentGateway/methods/PaymentGatewayMethods';
import NotFound from 'components/notFound/NotFound';
import TokenExpired from 'components/tokenExpired/TokenExpired';
import UnexpectedError from 'components/unexpectedError/UnexpectedError';
import AccessDenied from 'components/accessDenied/AccessDenied';
import PaymentStatus from 'components/paymentStatus/PaymentStatus';

const Router = () => (
    <BrowserRouter>
        <Switch>
            <Route
                path="/transactions/:transaction"
                exact
                component={PaymentGatewayMethods}
            />
            <Route
                path="/token-expired"
                exact
                component={TokenExpired}
            />
            <Route
                path="/error"
                exact
                component={UnexpectedError}
            />
            <Route
                path="/payments/:payment/status"
                exact
                component={PaymentStatus}
            />
            <Route
                path="/not-found"
                exact
                component={NotFound}
            />
            <Route
                path="/access-denied"
                exact
                component={AccessDenied}
            />
            <Route
                path="*"
                component={NotFound}
            />
        </Switch>
    </BrowserRouter>
);

export default Router;
