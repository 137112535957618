import React, { useEffect, useState } from 'react';
import isWebview from 'is-ua-webview';

import './view.scss';

import RedirectAfterTwentySeconds from 'utilities/redirectAfterTwentySecs/RedirectAfterTwentySecs';

import PaymentGatewayFooter from 'components/footer/PaymentGatewayFooter';
import PaymentGatewayHeader from 'components/header/PaymentGatewayHeader';

import sign from 'assets/images/sign.svg';

const TokenExpired = () => {
    const [buttonText, setButtonText] = useState('');
    const shopName = sessionStorage.getItem('shopName');

    useEffect(() => {
        const webview = isWebview(window.navigator.userAgent);

        if (webview) {
            setButtonText('Wróć do aplikacji');
        } else {
            setButtonText(`Wróć do ${shopName}`);
        }
    }, []);

    return (
        <div className="tokenExpired">
            <main className="errorToken">
                <div className="main__container">
                    <img
                        className="main__icon"
                        src={sign}
                        alt="sign"
                    />
                    <h2 className="headline">Ups! Twoja sesja wygasła.</h2>
                    <a
                        href={sessionStorage.getItem('returnUrl')}
                        className="submit"
                    >
                        {buttonText}
                    </a>
                    <RedirectAfterTwentySeconds />
                    <span className="errorToken--margin" />
                    <PaymentGatewayFooter />
                </div>
            </main>
            <PaymentGatewayHeader showExpiration={false} />
        </div>
    );
};

export default TokenExpired;
