import React from 'react';
import ReactDOM from 'react-dom';

import 'assets/styles/normalize.scss';
import 'assets/styles/fonts.scss';
import 'assets/styles/cookiebot.scss';

import Router from './router';
import { useCookiebot } from './hooks/useCookiebot';

const App = () => {
    useCookiebot();

    return <Router />;
};

ReactDOM.render(<App />, document.getElementById('application'));
