import React, { useState, useEffect } from 'react';
import isWebview from 'is-ua-webview';

import './view.scss';

import PaymentStatusDetails from 'components/paymentStatusDetails/PaymentStatusDetails';
import CheckoutHeader from 'components/checkoutHeader/CheckoutHeader';
import PaymentGatewayFooter from 'components/footer/PaymentGatewayFooter';

import statusNew from 'assets/images/ILUSTRACJA_nowa.svg';
import statusOk from 'assets/images/ILUSTRACJA_zrealizowana.svg';
import statusInProgress from 'assets/images/ILUSTRACJA_wtrakcie.svg';
import statusRejected from 'assets/images/ILUSTRACJA_odrzucona.svg';

const StatusPayment = ({ paymentStatus, paymentData }) => {
    const [buttonText, setButtonText] = useState('');
    const shopName = sessionStorage.getItem('shopName');
    const returnUrl = sessionStorage.getItem('returnUrl');

    useEffect(() => {
        const webview = isWebview(window.navigator.userAgent);

        if (webview) {
            setButtonText('Wróć do aplikacji');
        } else {
            setButtonText(`Wróć do ${shopName}`);
        }
    }, []);

    const handlePaymentStatus = status => {
        if (status === 1) {
            return 'Nowa';
        }

        if (status === 2) {
            return 'W trakcie';
        }

        if (status === 4) {
            return 'Zrealizowana';
        }

        if (status === 8) {
            return 'Odrzucona';
        }
    };

    const handlePaymentStatusDesc = status => {
        if (status === 1) {
            return 'Realizacja Twojej płatności jeszcze się nie rozpoczęła.';
        }

        if (status === 2) {
            return 'Twoja płatność jest w trakcie realizacji.';
        }

        if (status === 4) {
            return 'Twoja płatność została zrealizowana.';
        }

        if (status === 8) {
            return 'Twoja płatność została odrzucona.';
        }
    };

    const handlePaymentStatusIcon = status => {
        if (status === 1) {
            return statusNew;
        }

        if (status === 2) {
            return statusInProgress;
        }

        if (status === 4) {
            return statusOk;
        }

        if (status === 8) {
            return statusRejected;
        }
    };

    return (
        <main className="payment-status">
            <div className="payment-status__container">
                <img
                    className="payment-status__icon"
                    src={handlePaymentStatusIcon(paymentStatus)}
                    alt="icon"
                />
                <p className="payment-status__subheadline">
                    Status transakcji:
                </p>
                <h2 className="headline">
                    {handlePaymentStatus(paymentStatus)}
                </h2>

                <p className="payment-status__description">
                    {handlePaymentStatusDesc(paymentStatus)}
                </p>
                {sessionStorage.getItem('emailSource') === 'false' && (
                    <p className="standby__description">
                        Na podany przez Ciebie adres e-mail wysłaliśmy
                        potwierdzenie transakcji. Klikając w link podany w
                        wiadomości, sprawdzisz jej aktualny status.
                    </p>
                )}
                <PaymentStatusDetails paymentData={paymentData} />
                {paymentStatus === 1 && (
                    <a
                        className="payment-status__back"
                        href={returnUrl}
                    >
                        {buttonText}
                    </a>
                )}
                <PaymentGatewayFooter />
            </div>
            <CheckoutHeader />
        </main>
    );
};

export default StatusPayment;
