import React, { useState } from 'react';
import moment from 'moment';
import { Redirect } from 'react-router-dom';

export const handleTokenExpiration = () => {
    const [counter, setCounter] = useState();
    const [timeLeft, setTimeLeft] = useState();

    React.useEffect(() => {
        setCounter(parseInt(sessionStorage.getItem('tokenExpiration'), 10));
        const timer =
            counter > 0 &&
            setInterval(() => {
                const counting = counter - 1;
                sessionStorage.setItem(
                    'tokenExpiration',
                    JSON.stringify(counting),
                );
                setCounter(counting);
                const formatedTimeLeft = moment.unix(counting).format('mm:ss');
                setTimeLeft(formatedTimeLeft);
            }, 1000);

        return () => clearInterval(timer);
    });

    if (timeLeft === '00:00') {
        sessionStorage.setItem('tokenExpiration', 0);

        return (
            <Redirect
                to="/token-expired"
                push
            />
        );
    }

    if (!timeLeft) {
        return null;
    }

    return (
        <div className="header__countDown">
            Sesja:
            {timeLeft}
        </div>
    );
};
