import React, { useState, useEffect, useRef } from 'react';
import jwtDecode from 'jwt-decode';

import './view.scss';

import {
    getToken,
    getTransactions,
    getPaymentMethods,
    postPayment,
    getShopData,
    getPaymentProviderData,
} from 'api/requests';

import { compareSignature } from 'utilities/signatureCheckout/SignatureCheckout';

import PaymentGatewayHeader from 'components/header/PaymentGatewayHeader';
import PaymentGatewayFooter from 'components/footer/PaymentGatewayFooter';
import LoaderView from 'components/loading/LoaderView';

const PaymentGatewayMethods = () => {
    const [transaction, setTransaction] = useState('');
    const [shopPaymentMethods, setShopPaymentMethods] = useState([]);
    const [methodsFlag, setMethodsFlag] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isRegulationAccepted, setIsRegulationAccepted] = useState(false);
    const [agreementContent, setAgreementContent] = useState('');
    const [fee, setFee] = useState(0);
    const [isDisabled, setIsDisabled] = useState(true);
    const [paymentMethodId, setPaymentMethodId] = useState(null);
    const scroll = useRef(null);

    const executeScroll = () => {
        scroll.current.scrollIntoView();
    };

    useEffect(() => {
        sessionStorage.setItem('fullUrl', window.location.href);

        if (!sessionStorage.getItem('signature')) {
            sessionStorage.setItem(
                'signature',
                window.location.search.split('=').pop(),
            );
        }

        setTransaction(window.location.pathname.split('/transactions/').pop());
        sessionStorage.setItem(
            'transaction',
            window.location.pathname.split('/transactions/').pop(),
        );
        setIsLoading(true);
        compareSignature(transaction);
        setMethodsFlag(true);
        sessionStorage.setItem('transaction', transaction);
    }, [transaction]);

    const handleLoadingFlag = () => {
        setIsLoading(false);
    };

    const handleCommunication = async () => {
        sessionStorage.removeItem('hideReturnUrl');
        await getToken(sessionStorage.getItem('signature')).then(response => {
            const tokenJwt = jwtDecode(response.data.token);
            sessionStorage.setItem('token', response.data.token);
            const tokenExpiration = tokenJwt.exp - tokenJwt.iat;
            sessionStorage.setItem('tokenExpiration', tokenExpiration);
        });
        await getTransactions(transaction).then(response => {
            sessionStorage.setItem('providerName', response.data.providerName);
            sessionStorage.setItem('shopId', response.data.shopId);
            sessionStorage.setItem(
                'amount',
                parseInt(response.data.order.amount, 10),
                10,
            );
            sessionStorage.setItem(
                'referenceId',
                response.data.order.reference,
            );
            sessionStorage.setItem('returnUrl', response.data.returnUrl);
            sessionStorage.setItem('customerId', response.data.customerId);
            sessionStorage.setItem('description', response.data.description);
            response.data.hideReturnUrl &&
                sessionStorage.setItem(
                    'hideReturnUrl',
                    response.data.hideReturnUrl,
                );
        });
        await getPaymentMethods(sessionStorage.getItem('providerName')).then(
            response => {
                setShopPaymentMethods(response.data);

                const query = window.location.search.substring(1);

                if (query.length) {
                    if (
                        window.history !== undefined &&
                        window.history.pushState !== undefined
                    ) {
                        window.history.pushState(
                            {},
                            document.title,
                            window.location.pathname,
                        );
                    }
                }
            },
        );
        await getShopData(sessionStorage.getItem('shopId')).then(response => {
            sessionStorage.setItem('shopName', response.data.name);
            handleLoadingFlag();
        });
    };

    const selectPaymentMethod = async (paymentMethodUuid, paymentMethod) => {
        if (document.querySelector('.gateway__paymentIcon--active')) {
            document
                .querySelector('.gateway__paymentIcon--active')
                .classList.remove('gateway__paymentIcon--active');
        }

        paymentMethod.classList.add('gateway__paymentIcon--active');

        setPaymentMethodId(paymentMethodUuid);
        setIsDisabled(false);
        executeScroll();
    };

    const redirectToPayment = async () => {
        await postPayment(transaction, paymentMethodId).then(
            response => (window.location.href = response.data.redirectUrl),
        );
    };

    const handleRefresh = async () => {
        setIsLoading(true);
        await getPaymentMethods(sessionStorage.getItem('providerName'))
            .then(response => {
                setShopPaymentMethods(response.data);
            })
            .then(() => {
                handleLoadingFlag();
            });
    };

    const handleRegulationAccept = e => {
        setIsRegulationAccepted(e.target.checked);
    };

    const getProviderData = async () => {
        const { provider } = shopPaymentMethods[0];
        sessionStorage.setItem('provider', provider);
        await getPaymentProviderData(provider).then(({ data }) => {
            const { agreement, fee: dataFee } = data;

            setAgreementContent(agreement.content);

            if (dataFee > 0) {
                const formattedFee = (dataFee / 100)
                    .toFixed(2)
                    .replace('.', ',');
                setFee(formattedFee);
            }
        });
    };

    useEffect(() => {
        if (
            sessionStorage.getItem('token') === null &&
            sessionStorage.getItem('shopId') === null &&
            methodsFlag
        ) {
            handleCommunication();
        } else if (
            sessionStorage.getItem('token') &&
            sessionStorage.getItem('shopId') &&
            methodsFlag
        ) {
            handleRefresh();
        }
    }, [methodsFlag]);

    useEffect(() => {
        if (shopPaymentMethods.length) {
            getProviderData();
        }
    }, [shopPaymentMethods]);

    return isLoading ? (
        <LoaderView />
    ) : (
        <main className="main">
            <div className="methods main__container">
                <div className="gateway">
                    {shopPaymentMethods.map(({ id, name, icon, active }) => (
                        <div
                            key={name}
                            className="gateway__paymentMethod"
                        >
                            <button
                                className="gateway__paymentButton"
                                onClick={event =>
                                    active &&
                                    selectPaymentMethod(id, event.target)
                                }
                            >
                                <i
                                    className={
                                        active
                                            ? 'gateway__paymentIcon'
                                            : 'gateway__paymentIcon gateway__paymentIcon--disabled'
                                    }
                                    style={{
                                        background: `url(${icon})`,
                                        backgroundSize: '48px 48px',
                                    }}
                                />

                                <span className="gateway__paymentName">
                                    {name}
                                </span>
                            </button>
                        </div>
                    ))}
                </div>

                {agreementContent && (
                    <div className="field">
                        <div className="field__checkbox">
                            <label
                                className="checkbox__label"
                                htmlFor="regulation"
                            >
                                <input
                                    type="checkbox"
                                    id="regulation"
                                    checked={isRegulationAccepted}
                                    onChange={handleRegulationAccept}
                                    className="checkbox__input"
                                />

                                <span className="checkbox__ballot-box" />

                                <p
                                    className="checkbox__content"
                                    dangerouslySetInnerHTML={{
                                        __html: agreementContent,
                                    }}
                                />
                            </label>
                        </div>
                    </div>
                )}

                <button
                    type="submit"
                    className="submit"
                    disabled={isDisabled || !isRegulationAccepted}
                    onClick={redirectToPayment}
                    ref={scroll}
                >
                    Zapłać teraz
                </button>

                {!sessionStorage.getItem('hideReturnUrl') && (
                    <a
                        href={sessionStorage.getItem('returnUrl')}
                        className="methods__returnShop"
                    >
                        ◂ Wróć do {sessionStorage.getItem('shopName')}
                    </a>
                )}

                <PaymentGatewayFooter showLinks={false} />
            </div>
            <PaymentGatewayHeader
                showExpiration
                fee={fee}
            />
        </main>
    );
};

export default PaymentGatewayMethods;
