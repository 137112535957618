import axios from 'axios';

axios.interceptors.response.use(
    res => res,
    error => {
        if (error.response.status === 400 || error.response.status >= 500) {
            return (window.location.pathname = '/error');
        }

        if (error.response.status === 401 || error.response.status === 403) {
            return (window.location.pathname = '/access-denied');
        }

        if (error.response.status === 404) {
            return (window.location.pathname = '/not-found');
        }
    },
);

export const getToken = async signature => {
    const response = await axios.post(
        `${process.env.API_URL}/authentication/client/access-tokens?s=${signature}`,
    );

    return response;
};

export const getTransactions = async transaction => {
    const response = await axios.get(
        `${process.env.API_URL}/api/client/transactions/${transaction}`,
        {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
        },
    );

    return response;
};

export const getPaymentMethods = async provider => {
    const setProvider =
        provider !== 'null' ? `?provider[name]=${provider}` : '';

    const response = await axios.get(
        `${process.env.API_URL}/api/client/shops/${sessionStorage.getItem(
            'shopId',
        )}/payment-methods${setProvider}`,
        {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
        },
    );

    return response;
};

export const postPayment = async (transaction, paymentMethodUuid) => {
    const response = await axios.post(
        `${process.env.API_URL}/api/client/transactions/${transaction}/payments`,
        {
            method: `${paymentMethodUuid}`,
        },
        {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
        },
    );

    return response;
};

export const getCustomerData = async customerId => {
    const response = await axios.get(
        `${process.env.API_URL}/api/client/customers/${customerId}`,
        {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
        },
    );

    return response;
};

export const getPaymentMethod = async paymentMethodId => {
    const response = await axios.get(
        `${process.env.API_URL}/api/client/payment-methods/${paymentMethodId}`,
        {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
        },
    );

    return response;
};

export const getShopData = async shopId => {
    const response = await axios.get(
        `${process.env.API_URL}/api/client/shops/${shopId}`,
        {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
        },
    );

    return response;
};

export const getPayment = async paymentId => {
    const response = await axios.get(
        `${process.env.API_URL}/api/client/payments/${paymentId}`,
        {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
        },
    );

    return response;
};

export const getPaymentProviderData = async provider => {
    const response = await axios.get(
        `${process.env.API_URL}/api/client/providers/${provider}`,
        {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
        },
    );

    return response;
};
