import React, { useEffect, useState } from 'react';

import './view.scss';

const RedirectAfterTwentySeconds = () => {
    const [counter, setCounter] = useState(20);
    useEffect(() => {
        const timer =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

        return () => clearInterval(timer);
    }, [counter]);

    if (counter === 0) {
        window.location.href = sessionStorage.getItem('returnUrl');
    }

    return (
        <p className="redirect">
            Automatyczny powrót do strony nastąpi za
            {counter} sekund...
        </p>
    );
};
export default RedirectAfterTwentySeconds;
